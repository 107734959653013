<template>
    <div class="modal-background">
        <div class="modal">
            <div class="modal-content">
                <header>
                    <div @click="$emit('close')" class="close">x</div>
                    <h3>Reset Password</h3>
                </header>
                <div v-if="!showSuccess" class="form">
                    <p>Enter your Email to reset your password</p>
                    <form @submit.prevent>
                        <input v-model.trim="email" type="email" placeholder="max@mustermann.de" />
                    </form>
                    <p v-if="errorMsg !== ''" class="error">{{ errorMsg }}</p>
                    <button @click="resetPassword()" class="button">Reset</button>
                </div>
                <p v-else>Success! Check your email for a reset link.</p>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
    data() {
        return {
            email: '',
            errorMsg: '',
            showSuccess: false,
        }
    },
    methods: {
        async resetPassword() {
            this.errorMsg = ''

            try {
                await auth.sendPasswordResetEmail(this.email)
                this.showSuccess = true
            } catch (err) {
                this.errorMsg = err.message
            }
        }
    }

}
</script>

<style lang="scss" scoped>
    
</style>