<template>
  <div id="login">
    <transition name="fade">
      <PasswordReset v-if="showPasswordReset" @close="togglePasswordReset()" />
    </transition>
      <header>
        <h1>Login</h1>
      </header>
      <main>
        <!-- LOGIN FORM -------------->
        <form v-if="showLoginForm" @submit.prevent>
          <div>
            <label for="email1">Email</label>
            <input v-model.trim="loginForm.email" type="email" name="email" id="email1" placeholder="max@mustermann.de">
          </div>
          <div>
            <label for="password1">Password</label>
            <input v-model.trim="loginForm.password" type="password" name="password" id="password1" placeholder="**************">
          </div>
          <button @click="login()" class="button">Log In</button>
          <div class="extras">
            <a @click="togglePasswordReset()">Forgot Password</a>
          </div>
        </form>

        <!-- SIGNUP FORM -------------->
        <form v-else @submit.prevent>
          <div>
            <label for="name">Name</label>
            <input v-model.trim="signupForm.name" type="text" name="name" id="name" placeholder="Max Mustermann">
          </div>
          <div>
            <label for="email2">Email</label>
            <input v-model.trim="signupForm.email" type="text" name="email2" id="email2" placeholder="max@mustermann.de">
          </div>
          <div>
              <label for="password2">Password</label>
              <input v-model.trim="signupForm.password" type="password" name="password2" id="password2" placeholder="*******">
          </div>
          <button @click="signup()" class="button">Sign Up</button>
          <div class="extras">
            <a @click="toggleForm()">Back to login</a>
          </div>
        </form>
      </main>
  </div>
</template>

<script>
import PasswordReset from '@/components/PasswordReset'

export default {
  components: {
    PasswordReset
  },
  data() {
    return {
      loginForm: {
        email: '',
        password: ''
      },
       signupForm: {
        name: '',
        email: '',
        password: ''
      },
      showLoginForm: true,
      showPasswordReset: false
    }
  },
  methods: {
    toggleForm() {
      this.showLoginForm = !this.showLoginForm
    },
    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    login() {
      this.$store.dispatch('login', {
        email: this.loginForm.email,
        password: this.loginForm.password
      })
    },
    signup() {
      this.$store.dispatch('signup', {
        name: this.signupForm.name,
        email: this.signupForm.email,
        password: this.signupForm.password
      })
    }
  }
}
</script>

<style scoped lang="scss">
  
</style>